import "./App.css";
import React from 'react';
import Questions from "./Pages/QandAPage/Qustions";
import MainPage from "./Pages/MainPage/MainPage";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <MainPage/>
    // <Router>
    //   <Routes>
    //     <Route path="/" element={<MainPage />} />
    //     <Route path="/q&a" element={<Questions />} />
    //   </Routes>
    // </Router>
  );
}

export default App;
