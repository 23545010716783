import React from 'react';
import styles from "./Footer.module.css"
import starsLogo from "../../Images/Icons/starsGold.png"
import Newsletter from '../Newsletter/Newsletter';
const Footer = () => {
    return(
        <div id="Kontakts" className={styles.wrapper}>
            <img className={styles.starsLogo} src={starsLogo} alt='logo'/>
            <Newsletter/>
        </div>
    )
}

export default Footer