import React from "react";
import styles from "./HeaderMenu.module.css";

const HeaderMenu = ({ scrolled }) => {

  return (
    <ul
      className={`${styles.listWrapper} ${scrolled ? styles.scrolledMenu : ""}`}
    >
      <li>
        <a  href="#projects">projekty</a>
      </li>
      <li>
        <a href="/q&a">Q&A</a>
      </li>
      <li>
        <a  href="#gallery1">galeria</a>
      </li>
      {!scrolled && (
        <li>
          <a href="#Kontakts">kontakt</a>
        </li>
      )}
    </ul>

    
  );
};

export default HeaderMenu;
