import React, { useState } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import ReactCompareImage from "react-compare-image";
import styles from "./Slider.module.css";
import btnLeft from "../../Images/Icons/btnLeft.png";
import btnRight from "../../Images/Icons/btnRight.png";
import circleImg from "../../Images/Icons/photo_tag_ball.png";
import starImg from "../../Images/Icons/slider_current_photo_stars.png";

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = Array.from({ length: 11 }, (_, index) => ({
    before: require(`../../Images/galleryImages/before${index + 1}.jpg`),
    after: require(`../../Images/galleryImages/after${index + 1}.jpg`),
  }));

  const handleNext = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleCircleClick = (index) => {
    const distance = Math.abs(index - circlesBefore);
    const newIndex =
      currentIndex + (index > circlesBefore ? distance : -distance);
    if (newIndex >= 0 && newIndex < images.length) {
      setCurrentIndex(newIndex);
    }
  };

  const isPreviousDisabled = currentIndex === 0;
  const isNextDisabled = currentIndex === images.length - 1;

  const circlesBefore = currentIndex > 1 ? 2 : currentIndex;
  const circlesAfter =
    images.length - currentIndex - 1 > 2 ? 2 : images.length - currentIndex - 1;

  return (
    <div id="gallery1" className={styles.wrapper}>
      <div className={`${styles.sliderWrapper} ${styles.transition}`}>
        <div className={styles.buttonWrapperPrev}>
          <button
            className={styles.previousBtn}
            onClick={handlePrevious}
            disabled={isPreviousDisabled}
          >
            <img src={btnLeft} alt="btnPrevious" />
          </button>
        </div>
        <ReactCompareImage
          leftImage={images[currentIndex]?.before}
          rightImage={images[currentIndex]?.after}
          sliderLineWidth={4}
          handleSize={40}
          skeleton={<div>Loading...</div>}
        />
        <div className={styles.galleryId} id="gallery">
          {" "}
        </div>
        <div className={styles.buttonWrapperNext}>
          <button
            className={styles.nextBtn}
            onClick={handleNext}
            disabled={isNextDisabled}
          >
            <img src={btnRight} alt="btnNext" />
          </button>
        </div>
        <div className={styles.statusBar}>
          {images.length > 0 &&
            [...Array(circlesBefore)].map((_, index) => (
              <img
                key={index}
                src={circleImg}
                alt="circle"
                onClick={() => handleCircleClick(index)}
              />
            ))}
          <img src={starImg} alt="star" />
          {images.length > 0 &&
            [...Array(circlesAfter)].map((_, index) => (
              <img
                key={index}
                src={circleImg}
                alt="circle"
                onClick={() => handleCircleClick(circlesBefore + index + 1)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Slider;
