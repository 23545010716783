import React from 'react';
import styles from "./LeftSideHeader.module.css";
import Menu from '../Menu/Menu';
const LeftSideHeader = (props) => {
    return(
        <div className={styles.headerWrapper}>
            <div className={styles.headerLeftSideWrapper}>
                <Menu  openMenu={props.openMenu} closeMenu={props.closeMenu}/>
                <img className={styles.logoImg} src={props.logo} alt='logo'/>
            </div>
        </div>
    )
}

export default LeftSideHeader