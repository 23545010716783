import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import styles from "./MainPage.module.css";
import AboutSection from "../../Components/Sections/AboutSection/AboutSection";
import Gallery from "../../Components/Sections/GallerySection/Gallery";
import Footer from "../../Components/Footer/Footer";

const MainPage = () => {
  const [headerOpacity, setHeaderOpacity] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const galleryElement = document.getElementById("gallery");
      const galleryPosition = galleryElement.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const galleryHeight = galleryElement.offsetHeight;
      const visibleHeight = windowHeight - galleryPosition;
      const visiblePercentage = (visibleHeight / galleryHeight) * 100;
      const opacity = 1 - visiblePercentage / 90;
      setHeaderOpacity(opacity < 0 ? -opacity : opacity);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const screenWidth = window.innerWidth;
  const isWideScreen = screenWidth > 1300;

  return (
    <div className={styles.mainPageWrapper}>
      <Header opacity={isWideScreen ? headerOpacity : 1} />
      <AboutSection />
      <Gallery id="gallery" />
      <Footer />
    </div>
  );
};

export default MainPage;
