import React, { useState, useEffect } from "react";
import styles from "./Header.module.css";
import menuWhite from "../../Images/Icons/whiteMenu.png";
import logoWhite from "../../Images/Icons/whiteLogo.png";
import menuBlack from "../../Images/Icons/blackMenu.png";
import closeMenu from "../../Images/Icons/menuClose.png";
import logoBlack from "../../Images/Icons/blackLogo.png";
import LeftSideHeader from "../LeftSideHeader/LeftSideHeader";
import HeaderMenu from "../HeaderMenu/HeaderMenu";
import SideHeader from "../../Components/SideHeader/SideHeader";

const Header = ({ opacity }) => {
  const [scrolled, setScrolled] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [currentOpacity, setCurrentOpacity] = useState(opacity);

    useEffect(() => {
        const targetOpacity = opacity >= 0 ? opacity : 0; 
        // console.log(targetOpacity);
        if (currentOpacity < targetOpacity) {
            const interval = setInterval(() => {
                const newOpacity = currentOpacity + 0.01;
                if (newOpacity >= targetOpacity) {
                    clearInterval(interval);
                    setCurrentOpacity(targetOpacity);
                } else {
                    setCurrentOpacity(newOpacity);
                }
            }, 10);
            return () => clearInterval(interval);
        } else {
            setCurrentOpacity(targetOpacity);
        }
    }, [opacity, currentOpacity]);
    const display = (currentOpacity <= 0.4) ? 'none' : '';

    const headerStyle = {
        opacity: currentOpacity,
        display: display
    };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY <= 0) {
        setIsTop(true);
        setScrolled(false);
      } else {
        setIsTop(false);
        setScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={`${styles.headerWrapper} ${
        scrolled && !isTop && styles.scrolled
      }`}
      style={headerStyle}
    >
      <div
        className={`${styles.innerWrapper} ${
          scrolled && !isTop && styles.scrolledInner
        }`}
      >
        <div className={`${styles.header} `}>
          <LeftSideHeader
            closeMenu = {closeMenu}
            openMenu={scrolled ? menuBlack : menuWhite}
            logo={scrolled ? logoBlack : logoWhite}
          />
        </div>
        <HeaderMenu scrolled={scrolled} />
      </div>

      {/* {!isTop && <RightSideHeader />} */}
      <SideHeader scrolled={scrolled} />
    </div>
  );
};

export default Header;
